import axios from 'axios';
import store from '@/store';

axios.defaults.baseURL = process.env.VUE_APP_API_URI
axios.defaults.headers.common['Authorization'] = store.state.token;


const getData = e => e.data;

const api = {
    rainviewer:{
        getTiles:()=>
            axios.get(`https://api.rainviewer.com/public/weather-maps.json`).then(getData)
    },
    users:{
        login:(data)=>
            axios.post(`/token`, data).then(getData),
        getOne:(id)=>
            axios.get(`/users/${id}`).then(getData),
        get:()=>
            axios.get(`/users/list`).then(getData),
        getSubs:(user_id)=>
            axios.get(`/subs/${user_id}`).then(getData),
        create:(data)=>
            axios.post(`/users`, data).then(getData),
        update:(id,data)=>
            axios.put(`/users/${id}`, data).then(getData),
        updateViewNews:(id)=>
            axios.put(`/user/${id}/view_news`).then(getData)
    },
    alerts:{
        create:(data)=>
            axios.post(`/alerts`, data).then(getData)
    }
}

export default api;