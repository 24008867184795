<template>
  <v-app>
    <v-main>
      <v-col cols=12 class="text-center">
        <v-progress-circular indeterminate class="mb-12"/>
        <h1>Stormwatch est maintenant disponible sur notre Application <b>My Previmeteo</b></h1>
        <h2>Vous allez être redirigé dans quelques secondes...</h2>
      </v-col> 
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: "App",
  mounted()
  {
    setTimeout(()=>{
      window.location.href="https://previmeteo.com"
    },5000)
  }
  
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

$body-font-family: 'Open Sans';
$title-font: 'Open Sans';
.v-application {
   font-family: $body-font-family, sans-serif !important;
    .title { // To pin point specific classes of some components
       font-family: $title-font, sans-serif !important;
    }
 }
.leaflet-container {
  z-index: 1;
}
</style>
