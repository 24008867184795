<template>
  <v-col>
    <LMap
      style="height: 90vh"
      ref="map"
      :options="{
        minZoom: 5,
        maxZoom: 12,
        preferCanvas: true,
      }"
      :center="[47, 2]"
      :zoom="5"
    >
      <LRectangle
        :bounds="maxBounds"
        :color="$vuetify.theme.dark ? '#fff' : '#000'"
        :weight="1"
        :fill="false"
      />

      <LCircle
        v-if="targetPos"
        :color="targetColor"
        :radius="targetRadius || 500"
        :lat-lng="targetPos"
      />

       <template v-for="(user_sub, i) in user_subs">
        <LFeatureGroup :key="i">
        <LMarker :lat-lng="[user_sub.lat, user_sub.lon]">
          <LIcon iconUrl="/storm-marker.svg" />
        </LMarker>
        <LCircle
          color="red"
          :lat-lng="[user_sub.lat, user_sub.lon]"
          :radius="user_sub.radius1 * 1000"
        />
        <LCircle
          color="orange"
          :lat-lng="[user_sub.lat, user_sub.lon]"
          :radius="user_sub.radius2 * 1000"
        />
        <LCircle
          color="yellow"
          :lat-lng="[user_sub.lat, user_sub.lon]"
          :radius="user_sub.radius3 * 1000"
        />
        </LFeatureGroup>
      </template>
      <LTileLayer
        v-if="$vuetify.theme.dark"
        url="https://api.mapbox.com/styles/v1/pmcom/ck6m9eny40xsn1imy756u9tyw/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoicG1jb20iLCJhIjoiY2lxajgxbHF3MDBjZWkwbWMza2pwYzFoayJ9.a1ywamqhc2BKAdxMdgcBpA"
      />
      <LTileLayer
        v-else
        url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
      />
      <LTileLayer
        :options="{ zIndex: 100, bounds: maxBounds }"
        v-if="last_tile && !from && precipTile"
        :url="last_tile"
      />
      <LControl position="topright">
        <v-card>
          <v-card-text v-if="lastimpact">
            <v-col class="text-right py-0">
              <h3>
                <v-btn
                  icon
                  @click="
                    $refs.map.mapObject.setView(
                      [lastimpact.lat, lastimpact.lon],
                      8
                    )
                  "
                  ><v-icon color="red">mdi-lightning-bolt</v-icon></v-btn
                >
                {{ $t("map.LastImpacts") }}
              </h3>
            </v-col>
            <v-col class="text-right py-0">
              <b
                >{{ $t("map.At") }}
                {{ moment(lastimpact.time * 1000).format("DD/MM HH:mm") }}</b
              >
              <v-switch
                dense
                inset
                x-small
                v-if="false"
                v-model="tracking"
                label="Suivre"
              />
            </v-col>
          </v-card-text>
        </v-card>
      </LControl>
      <LControl position="bottomright">
        <v-switch inset v-model="$vuetify.theme.dark" :label="$t('map.Light/Dark')" />
      </LControl>
      <LControl position="bottomleft">
        <v-btn icon v-if="!isParamOpen" @click="isParamOpen = !isParamOpen">
          <v-icon v-if="!isParamOpen">mdi-cog</v-icon>
        </v-btn>
        <v-card v-if="isParamOpen">
          <v-btn icon @click="isParamOpen = !isParamOpen">
            <v-icon v-if="isParamOpen">mdi-minus</v-icon>
          </v-btn>
          <v-card-text>
            <v-text-field
              :disabled="liveMode"
              :label="$t('map.from')"
              v-model="from"
              type="datetime-local"
              filled
              dense
            />

            <v-divider class="py-2" />

            <v-btn
              small
              block
              @click="offset = 1"
              :color="offset == 1 ? 'primary' : ''"
              >1 {{ $t("map.Hour") }}</v-btn
            >

            <v-btn
              small
              block
              @click="offset = 3"
              :color="offset == 3 ? 'primary' : ''"
              >3 {{ $t("map.Hours") }}</v-btn
            >
            <v-btn
              small
              block
              @click="offset = 6"
              :color="offset == 6 ? 'primary' : ''"
              >6 {{ $t("map.Hours") }}</v-btn
            >

            <v-divider class="py-2" />

            <v-btn
              small
              block
              :color="state.filterPolarity == -1 ? 'primary' : ''"
              @click="updatePolarity(-1)"
              >{{ $t("map.Polarity") }} -</v-btn
            >

            <v-btn
              small
              block
              :color="state.filterPolarity == 1 ? 'primary' : ''"
              @click="updatePolarity(1)"
              >{{ $t("map.Polarity") }} +</v-btn
            >

            <v-divider class="py-2" />

            <v-btn
              small
              block
              :color="state.filterType == 0 ? 'primary' : ''"
              @click="updateType(0)"
              >{{ $t("map.CloudGround") }}</v-btn
            >

            <v-btn
              small
              block
              :color="state.filterType == 1 ? 'primary' : ''"
              @click="updateType(1)"
              >{{ $t("map.InsideCloud") }}</v-btn
            >

            <template v-if="!from && last_tile">
              <v-switch
                inset
                v-model="precipTile"
                :label="$t('map.PrecipRadar')"
              />
            </template>
            <v-divider class="py-2" />
            <v-btn
              small
              @click="liveMode = !liveMode"
              :color="liveMode ? 'red' : ''"
              ><v-icon small :color="liveMode ? 'white' : 'red'"
                >mdi-record</v-icon
              >
              Live</v-btn
            >
            <div class="legend my-2"></div>
            <v-row>
              <v-col>
                <template v-if="from">
                  {{
                    moment(from).subtract(offset, "hours").format("DD/MM HH")
                  }}h
                </template>
                <template v-else>
                  {{ moment().subtract(offset, "hours").format("DD/MM HH") }}h
                </template>
              </v-col>
              <v-col class="text-right">
                <template v-if="from">
                  {{ moment(from).format("DD/MM HH") }}h
                </template>
                <template v-else> {{ moment().format("DD/MM HH") }}h </template>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </LControl>
      <LControl position="bottomright">
        <v-progress-circular indeterminate v-if="loading" />
      </LControl>
    </LMap>
    <v-snackbar v-model="goSnackbar" top>
      <span class="green--text">{{ $t("map.NewImpacts") }}...</span>
    </v-snackbar>
    <v-snackbar v-model="noFlashes" top>
      <span class="green--text">Pas d'impacts pour cette periode...</span>
    </v-snackbar>
    <v-overlay v-if="!user.view_news">
      <v-card width="50vw">
        <v-card-title>Nouveautées de Stormwatch 2.2</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-img contain src="/img/news222.png" />
            </v-col>
            <v-col cols="6">
              <h3>Modification d'interface</h3>
              Le bouton pour réduire le réglage est dans l'onglet directement
            </v-col>
            <v-col cols="6">
              <v-img contain src="/img/news221.png" />
            </v-col>
            <v-col cols="6">
              <h3>Fonctionalité</h3>
              Lorsque vous cliquez sur un impact, vous obtenez également son
              rayon d'incertitude
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn block color="info" @click="setViewNews()">Ok merci !</v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>
  </v-col>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LRectangle,
  LControl,
  LCircle,
  LMarker,
  LIcon,
} from "vue2-leaflet";
import "@/plugins/leaflet-canvas-marker";
import L from "leaflet";
import chroma from "chroma-js";
import axios from "axios";
import moment from "moment-timezone";
import "@ansur/leaflet-pulse-icon";
import "@ansur/leaflet-pulse-icon/dist/L.Icon.Pulse.css";
// import "@/plugins/SmoothZoom";
import "@/plugins/NoGap";
import _ from "lodash";
import api from "@/api";
import { mapState } from "vuex";
export default {
  components: {
    LMap,
    LIcon,
    LTileLayer,
    LControl,
    LCircle,
    LMarker,
    LRectangle,
  },
  computed: {
    ...mapState(["user"]),
    impactsFiltered() {
      let out = [];
      this.impacts.forEach((impact) => {
        if (
          (this.state.filterPolarity === null ||
            impact.peak * this.state.filterPolarity >= 0) &&
          (this.state.filterType === null ||
            this.state.filterType == impact.type)
        ) {
          out.push(impact);
        }
      });
      return out;
    },
  },
  data: () => ({
    offset: 1,
    isParamOpen: true,
    from: null,
    targetPos: null,
    user_subs: [],
    state: {
      filterPolarity: null,
      filterType: null,
    },
    precipTile: false,
    tracking: false,
    ciLayer: null,
    newimpacts: null,
    loading: true,
    goSnackbar: false,
    lastmarker: null,
    liveMode: false,
    noFlashes: false,
    maxBounds: [
      [41, -7.1],
      [52.2, 10.21],
    ],
    lastimpact: null,
    last_tile: null,
    targetRadius: 0,
    targetColor: null,
    moment: moment,
    impacts: [],
    interval: null,
    legends: [],
  }),
  methods: {
    tooltipImpact(impact) {
      let dtImpact = this.moment(impact.time * 1000);
      let typeStr = null;
      if (impact.type == 0) {
        typeStr = this.$t("nuage-sol");
      } else {
        typeStr = this.$t("intra-nuageux");
      }

      let dtStr = dtImpact.format("DD/MM/YY HH:mm:ss");
      let pol = impact.peak < 0 ? "-" : "+";

      let tooltipStr = typeStr + " (" + pol + ")";
      tooltipStr += "<br>" + dtStr;
      tooltipStr +=
        "<br><b>"+this.$t('map.uncertainty')+"</b> " + (impact.ee_major || "500") + "m";
      tooltipStr += "<br><b>max </b>" + Math.round(impact.peak / 1000) + " kA";

      return tooltipStr;
    },

    updatePolarity(polarity) {
      this.state.filterPolarity =
        this.state.filterPolarity == polarity ? null : polarity;
    },
    setViewNews() {
      try {
        api.users.updateViewNews(this.user.id);
      } catch (err) {
        console.log(err);
      }
      this.user.view_news = 1;
    },
    updateType(type) {
      this.state.filterType = this.state.filterType == type ? null : type;
    },
    markerColor(time) {
      let nbSteps = this.offset; // TODO dynamic ?
      let colors = chroma
        .scale(["#ed0707", "#f08c22", "#fcfc68", "#40b81c", "#989898"])
        .colors(nbSteps);
      let from = moment();
      if (this.from) {
        from = this.from;
      }
      let partSize =
        (moment(from).unix() -
          moment(from).subtract(this.offset, "hours").unix()) /
        60 /
        nbSteps;
      // console.log('partSize',partSize)
      let idx = Math.floor((moment(from).unix() - time) / partSize / nbSteps);
      // console.log(idx)
      return colors[idx];
    },
    async getSubs() {
      this.user_subs = await api.users.getSubs(this.user.id);
    },
    async getFlashes(live = null) {
      this.loading = true;
      try {
        let query = "";
        if (this.from) {
          query = `?time=${moment(this.from)
            .subtract(this.offset, "hours")
            .unix()}-${moment(this.from).unix()}`;
        } else {
          query = `?time=${moment()
            .subtract(this.offset, "hours")
            .unix()}-${moment().unix()}`;
        }
        if (live) {
          query = `?time=${moment()
            .subtract(this.offset, "hours")
            .unix()}-${moment().unix()}`;
        }
        let res = await axios.get(
          `https://api.stormwatch.previmeteo.net/entln/flashes${query}`
        );
        if (!res.data) this.noFlashes = true;
        res.data = _.sortBy(res.data, "time");
        if (!this.ciLayer) {
          this.ciLayer = L.canvasIconLayer({}).addTo(this.$refs.map.mapObject);
        } else {
          this.ciLayer.clearLayers();
        }
        var markers = [];
        var marker;
        if (!res.data) res.data = [];
        if (this.lastimpact && res.data[res.data.length - 1]) {
          this.newimpacts =
            this.lastimpact.id != res.data[res.data.length - 1].id
              ? true
              : false;
        }
        this.impacts = res.data;
        this.impacts = this.impactsFiltered;
        this.lastimpact = this.impactsFiltered[this.impactsFiltered.length - 1];
        this.impactsFiltered.forEach((element) => {
          let color = this.markerColor(element.time);
          if (color) {
            color = color.replace("#", "%23");
          } else {
            color = "%23989898";
          }
          var icon = L.icon({
            iconUrl: `https://api.iconify.design/fa-solid/plus.svg?color=${color}&height=16`,
            iconSize: [10, 10],
            iconAnchor: [5, 5],
          });
          marker = L.marker([element.lat, element.lon], {
            icon: icon,
          }).bindPopup(this.tooltipImpact(element));
          marker.data = element;
          markers.push(marker);
        });

        let time = this.impactsFiltered[this.impactsFiltered.length - 1]
          ? this.impactsFiltered[this.impactsFiltered.length - 1].time
          : null;
        let ee_major = this.impactsFiltered[this.impactsFiltered.length - 1]
          ? this.impactsFiltered[this.impactsFiltered.length - 1].ee_major
          : null;

        var pulsingIcon = L.icon.pulse({
          iconSize: [10, 10],
          fillColor: this.markerColor(time),
          color: "#000",
        });
        if (this.lastmarker) {
          this.lastmarker.remove();
        }
        if (this.impactsFiltered[this.impactsFiltered.length - 1]) {
          this.lastmarker = L.marker(
            [
              this.impactsFiltered[this.impactsFiltered.length - 1].lat,
              this.impactsFiltered[this.impactsFiltered.length - 1].lon,
            ],
            {
              icon: pulsingIcon,
            }
          );
          this.lastmarker.bindPopup(
            `${this.tooltipImpact(
              this.impactsFiltered[this.impactsFiltered.length - 1]
            )}`
          );
          this.lastmarker.on("click", (e) => {
            this.targetPos = e.latlng;
            this.targetRadius = ee_major;
            this.targetColor = this.markerColor(time);
          });
          this.lastmarker.addTo(this.$refs.map.mapObject);
          if (this.tracking) {
            this.$refs.map.mapObject.setView(
              [
                this.impactsFiltered[this.impactsFiltered.length - 1].lat,
                this.impactsFiltered[this.impactsFiltered.length - 1].lon,
              ],
              8
            );
          }
          this.ciLayer.addOnClickListener((e, data) => {
            this.targetPos = [data[0].data.data.lat, data[0].data.data.lon];
            this.targetRadius = data[0].data.data.ee_major;
            this.targetColor = this.markerColor(data[0].data.data.time);
          });
          this.ciLayer.addLayers(markers);
        }
      } catch (err) {
        console.log(err);
      }
      this.getRainviewerTiles();
      this.loading = false;
    },
    async getRainviewerTiles() {
      let tiles = await api.rainviewer.getTiles();
      console.log(tiles);
      this.last_tile = `https://tilecache.rainviewer.com${tiles.radar.nowcast[0].path}/512/{z}/{x}/{y}/1/1_1.png`;
    },
  },
  mounted() {
    if (moment().format("H") >= 18 || moment().format("H") <= 7) {
      this.$vuetify.theme.dark = true;
    }
    this.getFlashes();
    this.getSubs();
  },
  watch: {
    from() {
      this.getFlashes();
    },
    state: {
      deep: true,
      handler() {
        this.getFlashes(this.liveMode);
      },
    },
    offset() {
      this.getFlashes(this.liveMode);
    },
    liveMode() {
      this.getFlashes(this.liveMode);
      this.from = null;
      clearInterval(this.interval);
      if (this.liveMode) {
        this.interval = setInterval(() => {
          this.getFlashes(true);
        }, 6000);
      }
    },
    newimpacts() {
      this.goSnackbar = true;
    },
  },
};
</script>

<style>
.legend {
  background: rgb(159, 159, 159);
  background: linear-gradient(
    90deg,
    #989898,
    #40b81c,
    #fcfc68,
    #f08c22,
    #ed0707
  );
  width: 100%;
  height: 10px;
}
</style>