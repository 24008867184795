<template>
  <Map />
</template>

<script>
  import Map from '@/components/Map'

  export default {
    name: 'Home',

    components: {
      Map
    }
  }
</script>
